<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="11" lg="11">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                    <v-subheader>PO#</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                    <span>{{ item.purchaseOrderName }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                    <v-subheader>Customer</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                    <span>{{ item.customerName }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                    <v-subheader>Total</v-subheader>
                  </v-col>
                  <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                    <span>{{ formatPrice(item.total) }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="createDP">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Create DP</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="createInvoiceByDO">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Create Invoice By DO</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "buffer-invoice-detail",
  props: {
    id: Number,
  },
  data: () => ({
    bufferInvoice: {},
  }),
  methods: {
    async getBufferInvoice() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bufferInvoice/getBufferInvoice", this.id)
        .then(response => {
          this.bufferInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    back() {
      this.$router.go(-1);
    },
    createDP() {
      this.$router.push({ name: "create-sale-down-payment-with-buffer", params: { id: this.id } });
    },
    createInvoiceByDO() {
      this.$router.push({ name: "create-sale-invoice-with-buffer", params: { id: this.id } });
    },
  },
  mounted() {
    this.getBufferInvoice();
  },
};
</script>

<style></style>
